import { CubedAnnotationResource, CubedResource, annotation_types } from '../types';
import * as fields from './fields';

export const REPORT_MCS_CHANNEL: CubedResource = {
    category: 'report',
    id: 'report-mcs-channel',
    displayName: 'Channels',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME],
    defaultOrderBy: fields.FM_SALES,
    dashboardBuilderEnabled: true,
    metrics: [fields.FM_SALES, fields.FM_REVENUE, fields.COST, fields.VISITS],
};

export const REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT: CubedResource = {
    category: 'report',
    id: 'report-channel-dashboard-goal-snapshot',
    displayName: 'Report Channel Dashboard Goal Snapshot',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL: CubedResource = {
    category: 'report',
    id: 'report-channel-dashboard-goal-snapshot-channel',
    displayName: 'Report Channel Dashboard Goal Channel Snapshot',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION_CHANNEL: CubedResource = {
    category: 'report',
    id: 'report-channel-dashboard-goal-completion-channel',
    displayName: 'Report Channel Dashboard Goal Completion Channel',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_CONTENT_DASHBOARD_PATH: CubedResource = {
    category: 'report',
    id: 'report-content-dashboard-path',
    displayName: 'Paths',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PATH_MARKET,
        fields.PATH_GEOGRAPHY,
        fields.PATH_LANGUAGE,
        fields.PATH_CATEGORY,
        fields.PATH_TOPIC,
        fields.PATH_CONTENT_TYPE,
        fields.PATH_CONTENT_LENGTH,
        fields.PATH_HOMEPAGE,
    ],
    defaultOrderBy: fields.UNIQUE_PAGE_VIEWS,
};

export const REPORT_CONTENT_DASHBOARD_PATH_CHANNEL: CubedResource = {
    category: 'report',
    id: 'report-content-dashboard-path-channel',
    displayName: 'Channels',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PATH_MARKET,
        fields.PATH_GEOGRAPHY,
        fields.PATH_LANGUAGE,
        fields.PATH_CATEGORY,
        fields.PATH_TOPIC,
        fields.PATH_CONTENT_TYPE,
        fields.PATH_CONTENT_LENGTH,
    ],
    defaultOrderBy: fields.UNIQUE_PAGE_VIEWS,
};

export const REPORT_CONTENT_DASHBOARD_DOMAIN_PERFORMANCE: CubedResource = {
    category: 'report',
    id: 'report-content-dashboard-domain-performance',
    displayName: 'Report Content Dashboard Path',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_MCS_CHANNEL_REALLOCATION_ATL: CubedResource = {
    category: 'report',
    id: 'report-mcs-channel-reallocation-atl',
    displayName: 'ATL Reallocation',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_VALIDATION_MESSAGE: CubedResource = {
    category: 'report',
    id: 'report-validation-message',
    displayName: 'Error Reporting',
    dateDimension: fields.DATE_DATETIME,
    defaultDimensions: [fields.ERROR_NAME],
    dimensions: [fields.ERROR_NAME, fields.ERROR_MESSAGE, fields.HOST, fields.PATH],
    defaultOrderBy: fields.COUNT,
};

export const REPORT_ATL_BASE_TRAFFIC_FORECAST: CubedResource = {
    category: 'report',
    id: 'report-atl-base-traffic-forecast',
    displayName: 'Above The Line',
    dateDimension: fields.VISIT_DATE_DATETIME,
    defaultDimensions: [fields.ATL],
    dimensions: [fields.ATL, fields.REFERER, fields.COUNTRY_NAME, fields.CITY_NAME],
    defaultOrderBy: fields.BASELINE_TRAFFIC,
};

export const REPORT_ATL_IMPRESSION_TRAFFIC_FORECAST: CubedResource = {
    category: 'report',
    id: 'report-atl-impression-traffic-forecast',
    displayName: 'Display Impressions',
    dateDimension: fields.VISIT_DATE_DATETIME,
    defaultDimensions: [fields.IMPRESSION_MODEL],
    dimensions: [fields.IMPRESSION_MODEL, fields.REFERER],
    defaultOrderBy: fields.INCREMENTAL_TRAFFIC,
};

export const REPORT_ATL_IMPRESSION_CAMPAIGN_TRAFFIC_FORECAST: CubedResource = {
    category: 'report',
    id: 'report-atl-impression-campaign-traffic-forecast',
    displayName: 'Display Impressions',
    dateDimension: fields.VISIT_DATE_DATETIME,
    defaultDimensions: [fields.IMPRESSION_MODEL],
    dimensions: [fields.IMPRESSION_MODEL, fields.CAMPAIGN_ID, fields.IMPRESSION_SOURCE, fields.IMPRESSION_MEDIUM],
    defaultOrderBy: fields.INCREMENTAL_TRAFFIC,
};

export const REPORT_MCS_UTM: CubedResource = {
    category: 'report',
    id: 'report-mcs-utm',
    displayName: 'UTM Parameters',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.UTM_SOURCE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.UTM_SOURCE,
        fields.UTM_MEDIUM,
        fields.UTM_CAMPAIGN,
        fields.UTM_TERM,
        fields.UTM_CONTENT,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_REFERRER: CubedResource = {
    category: 'report',
    id: 'report-mcs-referrer',
    displayName: 'Referrer URLs',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERRER_DOMAIN],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.REFERRER_DOMAIN, fields.REFERRER_PATH],
    defaultOrderBy: fields.FM_SALES,
};
export const REPORT_MCS_PAYMENTS: CubedResource = {
    category: 'report',
    id: 'report-mcs-payments',
    displayName: 'Payments',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PAYMENTS_PAYMENT_FROM],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PAYMENTS_PAYMENT_FROM,
        fields.PAYMENTS_PAYMENT_TO,
        fields.PAYMENTS_SENDER_CURRENCY,
        fields.PAYMENTS_RECEIVER_CURRENCY,
    ],
    defaultOrderBy: fields.FM_SALES,
};
export const REPORT_MCS_PAYMENTS_LANDING_PATH: CubedResource = {
    category: 'report',
    id: 'report-mcs-payments-landing-path',
    displayName: 'Landing Paths',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PAYMENTS_PAYMENT_FROM],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PAYMENTS_PAYMENT_FROM,
        fields.PAYMENTS_PAYMENT_TO,
        fields.PAYMENTS_SENDER_CURRENCY,
        fields.PAYMENTS_RECEIVER_CURRENCY,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_PAYMENTS: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-payments',
    displayName: 'Payments',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PAYMENTS_PAYMENT_FROM],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PAYMENTS_PAYMENT_FROM,
        fields.PAYMENTS_PAYMENT_TO,
        fields.PAYMENTS_SENDER_CURRENCY,
        fields.PAYMENTS_RECEIVER_CURRENCY,
    ],
    defaultOrderBy: fields.FM_SALES,
};
export const REPORT_MCS_LANDING: CubedResource = {
    category: 'report',
    id: 'report-mcs-landing-path',
    displayName: 'Landing Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.LANDING_PATH_HOST],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
        fields.LANDING_PATH_MARKET,
        fields.LANDING_PATH_GEOGRAPHY,
        fields.LANDING_PATH_LANGUAGE,
        fields.LANDING_PATH_CATEGORY,
        fields.LANDING_PATH_TOPIC,
        fields.LANDING_PATH_CONTENT_TYPE,
        fields.LANDING_PATH_CONTENT_LENGTH,
        fields.LANDING_PATH_HOMEPAGE,
    ],
    defaultOrderBy: fields.FM_SALES,
    dashboardBuilderEnabled: true,
    metrics: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.COST, fields.ASSISTS],
};

export const REPORT_MCS_LANDING_PATH_REGION: CubedResource = {
    category: 'report',
    id: 'report-mcs-landing-path-region',
    displayName: 'Region Landing Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.MCS_COUNTRY_NAME],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
        fields.MCS_CONTINENT_NAME,
        fields.MCS_COUNTRY_NAME,
        fields.MCS_CITY_NAME,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_LANDING_PATH_REFERRER: CubedResource = {
    category: 'report',
    id: 'report-mcs-landing-path-referrer',
    displayName: 'Referrer & Landing Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
        fields.REFERRER_DOMAIN,
        fields.REFERRER_PATH,
        fields.LANDING_PATH_MARKET,
        fields.LANDING_PATH_GEOGRAPHY,
        fields.LANDING_PATH_LANGUAGE,
        fields.LANDING_PATH_CATEGORY,
        fields.LANDING_PATH_TOPIC,
        fields.LANDING_PATH_CONTENT_TYPE,
        fields.LANDING_PATH_CONTENT_LENGTH,
        fields.LANDING_PATH_HOMEPAGE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_PPC: CubedResource = {
    category: 'report',
    id: 'report-mcs-ppc',
    displayName: 'Google Ads',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PPC_CAMPAIGN_NAME,
        fields.PPC_AD_GROUP_NAME,
        fields.PPC_KEYWORD,
        fields.ADVERTISING_CHANNEL_TYPE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_SALES_PATH: CubedResource = {
    category: 'report',
    id: 'report-mcs-sales-page',
    displayName: 'Sales Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.SALES_PATH_PATH],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.SALES_PATH_HOST,
        fields.SALES_PATH_PATH,
        fields.SALES_REFERRING_HOST,
        fields.SALES_REFERRING_PATH,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_PPC_LANDING: CubedResource = {
    category: 'report',
    id: 'report-mcs-ppc-landing',
    displayName: 'Google Ads',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PPC_LANDING_PATH_HOST,
        fields.PPC_LANDING_PATH_PATH,
        fields.PPC_CAMPAIGN_NAME,
        fields.PPC_AD_GROUP_NAME,
        fields.PPC_KEYWORD,
        fields.ADVERTISING_CHANNEL_TYPE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_SEOPT_GOOGLE_TRENDS: CubedResource = {
    category: 'report',
    id: 'report-seogd-google-trends-traffic',
    displayName: 'Weekly Search Trends',
    dateDimension: fields.SEO_TREND_DATE_DATE,
    defaultDimensions: [fields.MARKET_KEYWORD_COUNTRY],
    dimensions: [
        fields.MARKET_KEYWORD_COUNTRY,
        fields.CORE_PRODUCT,
        fields.CORE_PARENT_CAT,
        fields.CORE_CHILD_CAT,
        fields.CORE_SUB_CAT,
        fields.KEYWORD_MODIFIER,
        fields.LOCALE,
        fields.MARKET_KEYWORD_KEYWORD,
    ],
    defaultOrderBy: fields.GOOGLE_TRENDS_HITS,
};

export const REPORT_SEOPT_GOOGLE_TRENDS_URL: CubedResource = {
    category: 'report',
    id: 'report-seogd-google-trends-traffic',
    displayName: 'Keyword URLs',
    dateDimension: fields.SEO_TREND_DATE_DATE,
    defaultDimensions: [fields.MARKET_KEYWORD_MAPPED_PATH],
    dimensions: [fields.MARKET_KEYWORD_MAPPED_HOST, fields.MARKET_KEYWORD_MAPPED_PATH],
    defaultOrderBy: fields.GOOGLE_TRENDS_HITS,
    graphGroupByOverride: [fields.SEO_TREND_DATE_DATE],
};

export const REPORT_PPC_IMPRESSION_SHARE_PERFORMANCE: CubedResource = {
    category: 'report',
    id: 'report-ppc-impression-share-performance',
    displayName: 'PPC Impression Share Performance',
    dateDimension: fields.DATE_DATETIME,
    defaultDimensions: [fields.CAMPAIGN_NAME],
    dimensions: [fields.CAMPAIGN_NAME, fields.AD_GROUP_NAME, fields.KEYWORD, fields.ADVERTISING_CHANNEL_TYPE],
    defaultOrderBy: fields.PPC_IMPRESSIONS,
};

export const REPORT_MCS_SHOPPING: CubedResource = {
    category: 'report',
    id: 'report_mcs_shopping',
    displayName: 'Shopping',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.SHOPPING_CAMPAIGN_NAME],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.SHOPPING_CAMPAIGN_NAME,
        fields.SHOPPING_AD_GROUP_NAME,
        fields.SHOPPING_PRODUCT_ITEM_ID,
        fields.SHOPPING_CATEGORY1,
        fields.SHOPPING_CATEGORY2,
        fields.SHOPPING_CATEGORY3,
        fields.SHOPPING_CATEGORY4,
        fields.SHOPPING_CATEGORY5,
        fields.SHOPPING_PRODUCT_TYPE1,
        fields.SHOPPING_PRODUCT_TYPE2,
        fields.SHOPPING_PRODUCT_TYPE3,
        fields.SHOPPING_PRODUCT_TYPE4,
        fields.SHOPPING_PRODUCT_TYPE5,
    ],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_SKU_CHANNEL: CubedResource = {
    category: 'report',
    id: 'report-mcs-sku-channel',
    displayName: 'Channels',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.ECOMM_CATEGORY],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.ECOMM_CATEGORY, fields.ECOMM_NAME, fields.ECOMM_SKU],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_SKU_UTM: CubedResource = {
    category: 'report',
    id: 'report-mcs-sku-utm',
    displayName: 'UTM Parameters',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.ECOMM_CATEGORY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.ECOMM_CATEGORY,
        fields.ECOMM_NAME,
        fields.ECOMM_SKU,
        fields.UTM_SOURCE,
        fields.UTM_MEDIUM,
        fields.UTM_CAMPAIGN,
        fields.UTM_TERM,
        fields.UTM_CONTENT,
    ],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_SKU_REFERRER: CubedResource = {
    category: 'report',
    id: 'report-mcs-sku-referrer',
    displayName: 'Referrers',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.ECOMM_CATEGORY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.ECOMM_CATEGORY,
        fields.ECOMM_NAME,
        fields.ECOMM_SKU,
        fields.REFERRER_DOMAIN,
        fields.REFERRER_PATH,
    ],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_SKU_LANDING: CubedResource = {
    category: 'report',
    id: 'report-mcs-sku-landing-page',
    displayName: 'Landing Pages',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.ECOMM_CATEGORY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.ECOMM_CATEGORY,
        fields.ECOMM_NAME,
        fields.ECOMM_SKU,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
    ],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_SKU_PPC: CubedResource = {
    category: 'report',
    id: 'report-mcs-sku-ppc',
    displayName: 'PPC',
    dateDimension: fields.SALES_DATE_DATE,
    defaultDimensions: [fields.ECOMM_CATEGORY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.ECOMM_CATEGORY,
        fields.ECOMM_NAME,
        fields.ECOMM_SKU,
        fields.PPC_CAMPAIGN_NAME,
        fields.PPC_AD_GROUP_NAME,
        fields.PPC_KEYWORD,
    ],
    defaultOrderBy: fields.FM_SALES,
    graphGroupByOverride: [fields.SALES_DATE_DATE],
};

export const REPORT_MCS_PATTERN: CubedResource = {
    category: 'report',
    id: 'report-mcs-pattern',
    displayName: 'Patterns',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PATTERN],
    dimensions: [fields.PATTERN, fields.SUBCHANNEL_NAME, fields.REFERER],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_ADOBE_CAMPAIGN_LABELS: CubedResource = {
    category: 'report',
    id: 'report-mcs-adobe-campaign-labels',
    displayName: 'Adobe Campaign',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.LABEL_VALUE],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.LABEL_VALUE],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_SEOPT_KEYWORDS_LANDING: CubedResource = {
    category: 'report',
    id: 'report-seopt-keyword-landing-path-dashboard',
    displayName: 'SEO Keywords',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.MARKET_KEYWORD_COUNTRY],
    dimensions: [
        fields.MARKET_KEYWORD_COUNTRY,
        fields.LANGUAGE,
        fields.CORE_PRODUCT,
        fields.CORE_PARENT_CAT,
        fields.CORE_CHILD_CAT,
        fields.CORE_SUB_CAT,
        fields.MARKET_KEYWORD_KEYWORD,
        fields.KEYWORD_MODIFIER,
        fields.MARKET_KEYWORD_MAPPED_HOST,
        fields.MARKET_KEYWORD_MAPPED_PATH,
        fields.RANKED_PATH_HOST,
        fields.RANKED_PATH_PATH,
        fields.LOCALE,
    ],
    defaultOrderBy: fields.SEARCH_VOLUME,
};

export const REPORT_MONTHLY_CHANNEL_PERFORMANCE_REFERER: CubedResource = {
    category: 'report',
    id: 'report-monthly-channel-performance',
    displayName: 'Monthly Channel Performance',
    dateDimension: fields.REPORT_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER],
    defaultOrderBy: fields.VISITS,
};

export const REPORT_MONTHLY_CHANNEL_PERFORMANCE_DATE: CubedResource = {
    category: 'report',
    id: 'report-monthly-channel-performance',
    displayName: 'Monthly Channel Performance',
    dateDimension: fields.REPORT_DATE_DATETIME,
    defaultDimensions: [fields.REPORT_DATE_DATETIME],
    dimensions: [fields.REPORT_DATE_DATETIME],
    defaultOrderBy: fields.REPORT_DATE_DATETIME,
    forceDefaultOrderBy: true,
    forceDefaultOrderDir: true,
};

export const REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD: CubedResource = {
    category: 'report',
    id: 'report-mcs-landing-path-keyword',
    displayName: 'SEO Keywords',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.MARKET_KEYWORD_COUNTRY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.KEYWORD_LANDING_PATH_HOST,
        fields.KEYWORD_LANDING_PATH_PATH,
        fields.MARKET_KEYWORD_COUNTRY,
        fields.LANGUAGE,
        fields.CORE_PARENT_CAT,
        fields.CORE_CHILD_CAT,
        fields.CORE_SUB_CAT,
        fields.CORE_PRODUCT,
        fields.KEYWORD_MODIFIER,
        fields.LOCALE,
        fields.MARKET_KEYWORD_KEYWORD,
    ],
    defaultOrderBy: fields.VISITS,
    dashboardBuilderEnabled: true,
    metrics: [fields.ASSISTS, fields.BOUNCES, fields.FM_SALES, fields.FM_REVENUE, fields.VISITS],
};

export const REPORT_MCS_REGION: CubedResource = {
    category: 'report',
    id: 'report-mcs-region',
    displayName: 'Geography',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.MCS_COUNTRY_NAME],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.MCS_CONTINENT_NAME,
        fields.MCS_COUNTRY_NAME,
        fields.MCS_CITY_NAME,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_DEVICE: CubedResource = {
    category: 'report',
    id: 'report-mcs-device',
    displayName: 'Devices',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.DEVICE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.DEVICE,
        fields.DEVICE_FAMILY,
        fields.OS_FAMILY,
        fields.BROWSER_FAMILY,
    ],
    defaultOrderBy: fields.FM_SALES,
};
export const REPORT_MCS_REGION_SALES_JOURNEY: CubedResource = {
    category: 'report',
    id: 'report-mcs-region-sales-journey',
    displayName: 'New Vs Repeat',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.VISIT_TYPE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.VISIT_TYPE,
        fields.REPEAT_SALE,
        fields.COUNTRY_NAME,
        fields.CITY_NAME,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_MCS_SALES_JOURNEY: CubedResource = {
    category: 'report',
    id: 'report-mcs-sales-journey',
    displayName: 'New Vs Repeat',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.VISIT_TYPE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.VISIT_TYPE,
        fields.VISIT_NUMBER,
        fields.REPEAT_SALE,
        fields.SALE_NUMBER,
        fields.VISIT_IN_JOURNEY,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const REPORT_SEOPT_KEYWORD_VISIBILITY: CubedResource = {
    category: 'report',
    id: 'report-seogd-keyword-visibility',
    displayName: 'Keywords & Categories',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.MARKET_KEYWORD_COUNTRY],
    dimensions: [
        fields.MARKET_KEYWORD_COUNTRY,
        fields.LANGUAGE,
        fields.CORE_PARENT_CAT,
        fields.CORE_CHILD_CAT,
        fields.CORE_SUB_CAT,
        fields.MARKET_KEYWORD_KEYWORD,
        fields.CORE_PRODUCT,
        fields.KEYWORD_MODIFIER,
        fields.LOCALE,
    ],

    defaultOrderBy: fields.MARKET_SHARE,
    graphGroupByOverride: [fields.DATE_DATE],
    dashboardBuilderEnabled: true,
    metrics: [
        fields.SEARCH_VOLUME,
        fields.POSITION,
        fields.SEO_KEYWORD_VISIBILITY,
        fields.SEO_KEYWORD_MAX_VISIBILITY,
        fields.CURRENT_POSITION,
    ],
};

export const REPORT_SEOPT_KEYWORD_VISIBILITY_URL: CubedResource = {
    category: 'report',
    id: 'report-seogd-keyword-visibility-url',
    displayName: 'Keyword URLs',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.RANKING_URL],
    dimensions: [fields.RANKING_URL, fields.MARKET_KEYWORD_MAPPED_HOST, fields.MARKET_KEYWORD_MAPPED_PATH],

    defaultOrderBy: fields.MARKET_SHARE,
    graphGroupByOverride: [fields.DATE_DATE],
};

export const REPORT_PAGE_SPEED: CubedResource = {
    category: 'report',
    id: 'report-page-speed',
    displayName: 'Page Speed',
    dateDimension: fields.UPDATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PATH_MARKET,
        fields.PATH_GEOGRAPHY,
        fields.PATH_LANGUAGE,
        fields.PATH_CATEGORY,
        fields.PATH_TOPIC,
        fields.PATH_CONTENT_TYPE,
        fields.PATH_CONTENT_LENGTH,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.UPDATED_DATE],
};

export const REPORT_PAGE_SPEED_SECTION_DASHBOARD: CubedResource = {
    category: 'report',
    id: 'report-page-speed-section-dashboard',
    displayName: 'Page Speed',
    dateDimension: fields.UPDATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.LCP,
        fields.FID,
        fields.CLS,
        fields.FID_STATUS,
        fields.CLS_STATUS,
        fields.LCP_STATUS,
        fields.INP_STATUS,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.UPDATED_DATE],
};

export const REPORT_PAGE_TAGS: CubedResource = {
    category: 'report',
    id: 'report-page-tags',
    displayName: 'Page Tags',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PATH_MARKET,
        fields.PATH_GEOGRAPHY,
        fields.PATH_LANGUAGE,
        fields.PATH_CATEGORY,
        fields.PATH_TOPIC,
        fields.PATH_CONTENT_TYPE,
        fields.PATH_CONTENT_LENGTH,
        fields.PAGE_TITLE,
        fields.META_DESCRIPTION,
        fields.H1,
        fields.H2,
        fields.CONTAINS_SCHEMA,
        fields.JSON,
        fields.MICRODATA,
        fields.RDFA,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.CREATED_DATE],
};

export const REPORT_PAGE_TAGS_SECTION_DASHBOARD: CubedResource = {
    category: 'report',
    id: 'report-page-tags-section-dashboard',
    displayName: 'Page Tags',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PAGE_TITLE,
        fields.META_DESCRIPTION,
        fields.H1,
        fields.H2,
        fields.CONTAINS_SCHEMA,
        fields.JSON,
        fields.MICRODATA,
        fields.RDFA,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.CREATED_DATE],
};

export const REPORT_PAGE_IMAGES: CubedResource = {
    category: 'report',
    id: 'report-page-images',
    displayName: 'Page Images',
    dateDimension: fields.UPDATED_DATE,
    dimensions: [fields.FILE_TYPE, fields.IMAGE_URL, fields.ALT_TEXT, fields.PATH_PATH],
};

export const REPORT_TECH_SEO: CubedResource = {
    category: 'report',
    id: 'report-tech-seo',
    displayName: 'Tech SEO',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.PATH_MARKET,
        fields.PATH_GEOGRAPHY,
        fields.PATH_LANGUAGE,
        fields.PATH_CATEGORY,
        fields.PATH_TOPIC,
        fields.PATH_CONTENT_TYPE,
        fields.PATH_CONTENT_LENGTH,
        fields.CANONICAL,
        fields.HEADER_CANONICAL,
        fields.META_ROBOTS,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.CREATED_DATE],
};

export const REPORT_TECH_SEO_SECTION_DASHBOARD: CubedResource = {
    category: 'report',
    id: 'report-tech-seo-section-dashboard',
    displayName: 'Tech SEO',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [
        fields.PATH_HOST,
        fields.PATH_PATH,
        fields.CANONICAL,
        fields.DOUBLE_CANONICAL,
        fields.HEADER_CANONICAL,
        fields.META_ROBOTS,
        fields.STATUS_CODE,
    ],
    defaultOrderBy: fields.VISITS,
    graphGroupByOverride: [fields.CREATED_DATE],
};

export const REPORT_TECH_SEO_CSV: CubedResource = {
    category: 'config',
    id: 'report-tech-seo-csv',
    displayName: 'Report Tech SEO CSV',
    dateDimension: fields.UPDATED_DATE,
    defaultOrderBy: fields.UPDATED_DATE,
};

export const REPORT_SEOPT_KEYWORD_VISIBILITY_URL_CSV: CubedResource = {
    category: 'config',
    id: 'report-seopt-keyword-visibility-url-csv',
    displayName: 'Report SEO, Rank, Visibility & Marketshare',
    dateDimension: fields.DATE_DATE,
    defaultOrderBy: fields.DATE_DATE,
};

export const REPORT_GRID_CARBON_PATH: CubedResource = {
    category: 'report',
    id: 'report-grid-carbon-path',
    displayName: 'Paths',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.PATH_HOST, fields.PATH_PATH],
    defaultOrderBy: fields.GRID_CARBON,
    graphGroupByOverride: [fields.DATE_DATE],
};

export const REPORT_GRID_CARBON_UTM: CubedResource = {
    category: 'report',
    id: 'report-grid-carbon-utm',
    displayName: 'UTM Parameters',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.UTM_SOURCE,
        fields.UTM_MEDIUM,
        fields.UTM_CAMPAIGN,
        fields.UTM_TERM,
        fields.UTM_CONTENT,
    ],
    defaultOrderBy: fields.GRID_CARBON,
    graphGroupByOverride: [fields.DATE_DATE],
};

export const REPORT_GRID_CARBON_REGION: CubedResource = {
    category: 'report',
    id: 'report-grid-carbon-region',
    displayName: 'Geography',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.MCS_CONTINENT_NAME,
        fields.MCS_COUNTRY_NAME,
        fields.MCS_CITY_NAME,
    ],
    defaultOrderBy: fields.GRID_CARBON,
    graphGroupByOverride: [fields.DATE_DATE],
};

export const REPORT_SEGMENT: CubedResource = {
    category: 'segmenter',
    id: 'report-segment',
    displayName: 'Segment',
    dateDimension: fields.UPDATED_DATETIME,
};

export const REPORT_SEGMENTER_SEGMENT: CubedResource = {
    category: 'segmenter',
    id: 'report-segmenter-segment',
    displayName: 'Segment Visitors',
    dateDimension: fields.DATE_DATE,
    defaultDimensions: [fields.SEGMENTER],
    dimensions: [fields.SEGMENTER],
    defaultOrderBy: fields.VISITORS,
};

export const REPORT_SEGMENTER_SEGMENT_VENN: CubedResource = {
    category: 'segmenter',
    id: 'report-segmenter-segment-venn',
    displayName: 'Segment Venn Diagram',
    dateDimension: fields.START_DATETIME,
    defaultDimensions: [fields.SEGMENTER],
    dimensions: [fields.SEGMENTER],
    defaultOrderBy: fields.VISITORS,
};

export const MAPPED_KEYWORD_PATH: CubedResource = {
    category: 'report',
    id: 'mapped-keyword-path',
    displayName: 'Mapped Keywords',
    dateDimension: fields.UPDATED_DATETIME,
};

// NO RESOURCE?
export const PAGE_IMAGE: CubedResource = {
    category: 'report',
    id: 'page-image',
    displayName: 'Page Image',
    dateDimension: fields.UPDATED_DATE,
};

// NO RESOURCE
export const PAGE_SCHEMA: CubedResource = {
    category: 'report',
    id: 'page-schema',
    displayName: 'Page Schema',
    dateDimension: fields.UPDATED_DATE,
};

export const REPORT_SYNCS: CubedResource = {
    category: 'report',
    id: 'report-syncs',
    displayName: 'Sync Data',
    dateDimension: fields.CREATED_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME],
    defaultOrderBy: fields.SYNC_COUNT,
};

export const REPORT_PROPENSITY_MODEL: CubedResource = {
    category: 'report',
    id: 'report-propensity-model',
    displayName: 'Active Propensity Models',
    dateDimension: fields.MODEL_UPDATED_DATETIME,
    defaultDimensions: [fields.PRODUCT],
    dimensions: [fields.PRODUCT],
    defaultOrderBy: fields.MODEL_NAME,
};

export const REPORT_PROPENSITY_MODEL_PERFORMANCE: CubedResource = {
    category: 'report',
    id: 'agg-model-performance',
    displayName: 'Propensity Model Performance ',
    dateDimension: fields.MODEL_CREATED_DATETIME,
    defaultDimensions: [fields.PRODUCT],
    dimensions: [fields.PRODUCT],
    defaultOrderBy: fields.MODEL_NAME,
};

export const REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS: CubedResource = {
    category: 'report',
    id: 'report-seogd-googleconsole-index-status',
    displayName: 'Tech SEO',
    dateDimension: fields.CREATED_DATE,
    defaultDimensions: [fields.PATH_HOST],
    dimensions: [fields.PATH_HOST, fields.PATH_PATH, fields.INDEXED_VERDICT],
    defaultOrderBy: fields.UPDATED_DATE,
    graphGroupByOverride: [fields.CREATED_DATE],
};

// Attribution Comparison

export const ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-channel',
    displayName: 'Channels',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL_REALLOCATION_ATL: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-channel-reallocation-atl',
    displayName: 'Channels',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_UTM: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-utm',
    displayName: 'UTM Parameters',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.UTM_SOURCE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.UTM_SOURCE,
        fields.UTM_MEDIUM,
        fields.UTM_CAMPAIGN,
        fields.UTM_TERM,
        fields.UTM_CONTENT,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-referrer',
    displayName: 'Referrer URLs',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERRER_DOMAIN],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.REFERRER_DOMAIN, fields.REFERRER_PATH],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-sales-page',
    displayName: 'Sales Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.SALES_PATH_PATH],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.SALES_PATH_HOST,
        fields.SALES_PATH_PATH,
        fields.SALES_REFERRING_HOST,
        fields.SALES_REFERRING_PATH,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-landing-path',
    displayName: 'Landing Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.LANDING_PATH_HOST],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
        fields.LANDING_PATH_MARKET,
        fields.LANDING_PATH_GEOGRAPHY,
        fields.LANDING_PATH_LANGUAGE,
        fields.LANDING_PATH_CATEGORY,
        fields.LANDING_PATH_TOPIC,
        fields.LANDING_PATH_CONTENT_TYPE,
        fields.LANDING_PATH_CONTENT_LENGTH,
        fields.LANDING_PATH_HOMEPAGE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_PPC: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-ppc',
    displayName: 'Google Ads',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.PPC_CAMPAIGN_NAME,
        fields.PPC_AD_GROUP_NAME,
        fields.PPC_KEYWORD,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-ppc-landing',
    displayName: 'Google Ads',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_HOST,
        fields.LANDING_PATH,
        fields.PPC_CAMPAIGN_NAME,
        fields.PPC_AD_GROUP_NAME,
        fields.PPC_KEYWORD,
        fields.ADVERTISING_CHANNEL_TYPE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report_mcs_shopping',
    displayName: 'Shopping',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PPC_CAMPAIGN_NAME],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.SHOPPING_CAMPAIGN_NAME,
        fields.SHOPPING_AD_GROUP_NAME,
        fields.SHOPPING_PRODUCT_ITEM_ID,
        fields.SHOPPING_CATEGORY1,
        fields.SHOPPING_CATEGORY2,
        fields.SHOPPING_CATEGORY3,
        fields.SHOPPING_CATEGORY4,
        fields.SHOPPING_CATEGORY5,
        fields.SHOPPING_PRODUCT_TYPE1,
        fields.SHOPPING_PRODUCT_TYPE2,
        fields.SHOPPING_PRODUCT_TYPE3,
        fields.SHOPPING_PRODUCT_TYPE4,
        fields.SHOPPING_PRODUCT_TYPE5,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-pattern',
    displayName: 'Patterns',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.PATTERN],
    dimensions: [fields.PATTERN, fields.SUBCHANNEL_NAME, fields.REFERER],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-adobe-campaign-labels',
    displayName: 'Adobe Campaign',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.LABEL_VALUE],
    dimensions: [fields.REFERER, fields.SUBCHANNEL_NAME, fields.LABEL_VALUE],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-landing-path-referrer',
    displayName: 'Referrer & Landing Pages',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.REFERER],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.LANDING_PATH_HOST,
        fields.LANDING_PATH_PATH,
        fields.REFERRER_DOMAIN,
        fields.REFERRER_PATH,
        fields.LANDING_PATH_MARKET,
        fields.LANDING_PATH_GEOGRAPHY,
        fields.LANDING_PATH_LANGUAGE,
        fields.LANDING_PATH_CATEGORY,
        fields.LANDING_PATH_TOPIC,
        fields.LANDING_PATH_CONTENT_TYPE,
        fields.LANDING_PATH_CONTENT_LENGTH,
        fields.LANDING_PATH_HOMEPAGE,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_REGION: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-region',
    displayName: 'Geography',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.MCS_COUNTRY_NAME],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.MCS_CONTINENT_NAME,
        fields.MCS_COUNTRY_NAME,
        fields.MCS_CITY_NAME,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-device',
    displayName: 'Devices',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.DEVICE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.DEVICE,
        fields.DEVICE_FAMILY,
        fields.OS_FAMILY,
        fields.BROWSER_FAMILY,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-mcs-sales-journey',
    displayName: 'Sales Journeys',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.VISIT_TYPE],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.VISIT_TYPE,
        fields.VISIT_NUMBER,
        fields.REPEAT_SALE,
        fields.SALE_NUMBER,
        fields.VISIT_IN_JOURNEY,
    ],
    defaultOrderBy: fields.FM_SALES,
};

export const ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD: CubedResource = {
    category: 'report',
    id: 'attribution-comparison-report-seogd-keyword-visits',
    displayName: 'SEO Keywords',
    dateDimension: fields.SALES_DATE_DATETIME,
    defaultDimensions: [fields.MARKET_KEYWORD_COUNTRY],
    dimensions: [
        fields.REFERER,
        fields.SUBCHANNEL_NAME,
        fields.KEYWORD_LANDING_PATH_HOST,
        fields.KEYWORD_LANDING_PATH_PATH,
        fields.MARKET_KEYWORD_COUNTRY,
        fields.LANGUAGE,
        fields.CORE_PARENT_CAT,
        fields.CORE_CHILD_CAT,
        fields.CORE_SUB_CAT,
        fields.CORE_PRODUCT,
        fields.KEYWORD_MODIFIER,
        fields.LOCALE,
        fields.MARKET_KEYWORD_KEYWORD,
    ],
    defaultOrderBy: fields.VISITS,
};

// Annotations
export const ANNOTATIONS_GOOGLE_UPDATES: CubedAnnotationResource = {
    category: 'config',
    id: 'annotation',
    displayName: 'Google Updates',
    type: annotation_types.GOOGLE_ALGORITHM,
};

// Channel Insights Dash

export const REPORT_MC_DASH: CubedResource = {
    category: 'report',
    id: 'report-mc-dash',
    displayName: 'Report MC Dash',
    dateDimension: fields.DATE_DATETIME,
};

export const REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION: CubedResource = {
    category: 'report',
    id: 'report-channel-dashboard-goal-completion',
    displayName: 'Report Channel Dashboard Goal Completion',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_DASH_PRODUCT: CubedResource = {
    category: 'report',
    id: 'report-product',
    displayName: 'Goals',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_CHANNEL_DASHBOARD_GOAL_VENN: CubedResource = {
    category: 'report',
    id: 'report-channel-dashboard-goal-venn',
    displayName: 'Report Channel Dashboard Goal Venn',
    dateDimension: fields.START_DATETIME,
};

export const AGG_CUSTOMER_JOURNEY_COMPLEXITY: CubedResource = {
    category: 'report-view',
    id: 'goal-completion-complexity-funnel',
    displayName: 'Customer Journey Complexity',
    dateDimension: fields.SALES_DATE_DATE,
};

// Channel Detail Dash

export const REPORT_MCS_AFFILIATE: CubedResource = {
    category: 'report',
    id: 'report-mcs-affiliate',
    displayName: 'Affiliates',
    dateDimension: fields.SALES_DATE_DATE,
};

export const PATH_SUBDIRECTORY_DOMAIN: CubedResource = {
    category: 'report',
    id: 'path-subdirectory-domain-lite',
    displayName: 'Path Subdirectory Domain',
    dateDimension: fields.UPDATED_DATETIME,
};

export const REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE: CubedResource = {
    category: 'report',
    id: 'report-content-dashboard-path-performance',
    displayName: 'Path Performance',
    dateDimension: fields.SALES_DATE_DATE,
};

export const REPORT_PAGE_ATTRIBUTION_VALUE: CubedResource = {
    category: 'report',
    id: 'report-page-attribution-value',
    displayName: 'Page Attribution Value',
    dateDimension: fields.SALES_DATE_DATE,
};
