import * as fields from './fields';
import * as resources from './resources';
import moment from 'moment';
import { firstThreeSaturdaysThisMonth, getDateOfTheDay } from '../helpers/valid-date-helper';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Google Search Trends',
    reportDescription:
        'Explore search trends from Google Trends by keyword Market and Category. This report updates on the 1st of each month, and runs two weeks in arrears.',
    dateDimension: fields.SEO_TREND_DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
        // We pull keywords on the 1st and 14th of the month
        // In current month, we should only be allowed to check
        // at most first three Saturdays
        datePicker: {
            customStartDate: () => {
                const lastValidDay = getDateOfTheDay(moment(), 'Saturday');
                const firstThreeSaturdays = firstThreeSaturdaysThisMonth();

                const currentDate = moment().date();
                const lastValidDate = lastValidDay.date();

                if (currentDate <= firstThreeSaturdays[0].date()) {
                    return firstThreeSaturdays[0].subtract(2, 'weeks');
                }
                if (lastValidDate <= 14 && currentDate < 14) {
                    return firstThreeSaturdays[0].subtract(1, 'week');
                }
                if (lastValidDate <= 14 && currentDate >= 14) {
                    return lastValidDay.subtract(1, 'week');
                }
                if (lastValidDate === firstThreeSaturdays[1].date()) {
                    return firstThreeSaturdays[0];
                }
                if (lastValidDay.isAfter(firstThreeSaturdays[2]) || lastValidDate === firstThreeSaturdays[2].date()) {
                    return firstThreeSaturdays[1];
                }
                if (lastValidDate === firstThreeSaturdays[0].date() && currentDate === lastValidDate) {
                    return lastValidDay.subtract(2, 'weeks');
                }
                if (lastValidDate === firstThreeSaturdays[0].date()) {
                    return lastValidDay.subtract(1, 'week');
                }

                return lastValidDay.subtract(1, 'week');
            },
            customEndDate: () => {
                const lastValidDay = getDateOfTheDay(moment(), 'Saturday');
                const firstThreeSaturdays = firstThreeSaturdaysThisMonth();

                const currentDate = moment().date();
                const lastValidDate = lastValidDay.date();

                if (currentDate <= firstThreeSaturdays[0].date()) {
                    return firstThreeSaturdays[0].subtract(1, 'week');
                }
                if (lastValidDate <= 14 && currentDate < 14) {
                    return firstThreeSaturdays[0];
                }
                if (lastValidDate <= 14 && currentDate >= 14) {
                    return lastValidDay;
                }
                if (lastValidDate === firstThreeSaturdays[1].date()) {
                    return firstThreeSaturdays[1];
                }
                if (lastValidDay.isAfter(firstThreeSaturdays[2]) || lastValidDate === firstThreeSaturdays[2].date()) {
                    return firstThreeSaturdays[2];
                }
                if (lastValidDate === firstThreeSaturdays[0].date() && currentDate <= firstThreeSaturdays[0].date()) {
                    return lastValidDay.subtract(1, 'day');
                }
                if (lastValidDate === firstThreeSaturdays[0].date()) {
                    return firstThreeSaturdays[0];
                }
                return lastValidDay;
            },
            // disable day if date is not a Saturday or is a Saturday but not the 1st of the current month
            disabledDays: function (date) {
                // Get the first day of the month
                let firstDayOfMonth = moment({ year: moment(date).year(), month: moment(date).month(), day: 1 });

                // Find the first Saturday
                let firstSaturday = firstDayOfMonth.day(6);
                if (firstSaturday.date() > 7) {
                    firstSaturday = firstSaturday.add(1, 'week');
                }
                let secondSaturday = firstSaturday.clone().add(1, 'week');

                // Get the third Saturday by adding two weeks to the first Saturday
                let thirdSaturday = firstSaturday.clone().add(2, 'weeks');

                const disableCurrentMonth =
                    moment(date) > moment().day() &&
                    moment(date).month() === moment().month() &&
                    moment(date).date() !== 1;
                let disableSecondSaturday = false;
                if (moment(date).date() <= 14 && moment(date) === secondSaturday) {
                    disableSecondSaturday = true;
                }

                if (
                    (moment(date).date() > thirdSaturday.date() && disableCurrentMonth) ||
                    moment(date).format('dddd') !== 'Saturday' ||
                    (disableCurrentMonth && disableSecondSaturday)
                ) {
                    return true;
                }
                return false;
            },
        },
    },
    resources: [resources.REPORT_SEOPT_GOOGLE_TRENDS, resources.REPORT_SEOPT_GOOGLE_TRENDS_URL],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'report-seogd-google-trends-traffic',
        allYMetrics: [
            fields.GOOGLE_TRENDS_HITS,
            fields.GOOGLE_TRENDS_CHANGE_OVER_TIME,
            fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_PCT,
        ],
        initialYMetrics: [fields.GOOGLE_TRENDS_HITS],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
    },
    table: {
        hideTotals: true,
        tabListItems: [
            {
                tabName: 'Search Trends',
                displayName: 'Search Trends',
                isSelected: true,
                metrics: [
                    fields.GOOGLE_TRENDS_HITS,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_PCT,
                ],
            },
            {
                tabName: 'Search Trends YoY',
                displayName: 'Search Trends YoY',
                isSelected: true,
                metrics: [
                    fields.GOOGLE_TRENDS_HITS_SD_YOY,
                    fields.GOOGLE_TRENDS_HITS_ED_YOY,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_LAST_YEAR,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.MARKET_KEYWORD_COUNTRY.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PRODUCT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PARENT_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_CHILD_CAT,
                    },
                ],
            },
            [fields.CORE_CHILD_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_SUB_CAT,
                    },
                ],
            },
            [fields.CORE_SUB_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.KEYWORD_MODIFIER.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.LOCALE,
                    },
                ],
            },
            [fields.LOCALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
